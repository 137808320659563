<template>
  <div class="content">
    <div>
      <div v-if="!onEdit" style="margin-top: 20px">
      </div>
      <div v-else class="mt-3 mb-3">
        <EditForm
          type="edit"
          :vehicleData="info"
          @onCancel="() => (this.onEdit = false)"
          @changeData="changeData"
        />
      </div>
      <div v-if="!onEdit">
        <div class="fs-20 font-weight-bold mt-15 top-description">
          {{ info.rego ? `[${info.rego}]` : '' }} Please fill out your car information
        </div>
        <b-card class="pb-4 rounded-2 shadow-sm mb-4">
          <div class="center mb-4">
            <SectionTitle sectionTitle="CAR INFORMATION"></SectionTitle>
            <b-row>
              <b-col cols="12" md="6">
                <custom-select
                  label="State"
                  v-model="info.location"
                  :options="states"
                  :error="$v.info.location.$error"
                  helperText="Please select state"
                  name="state"
                  :isFocus="curStepInput === 1"
                  @setNextInput="setNextInputNumber"
                />
              </b-col>
              <b-col cols="12" md="6">
                <custom-autocomplete-input
                  label="Make"
                  v-model="info.make"
                  :options="makes"
                  :error="$v.info.make.$error"
                  helperText="Please enter make"
                  name="make"
                  :isFocus="curStepInput === 2"
                  @setNextInput="setNextInputNumber"
                  placeholder="Enter make"
                  :isLoadAble="true"
                />
              </b-col>
              <b-col cols="12" md="6">
                <custom-clear-select
                  id="model"
                  label="Model"
                  v-model="info.model"
                  :isClear="info.model !== '' && models.length > 1"
                  :options="models"
                  :error="$v.info.model.$error"
                  helperText="Please select model"
                  name="model"
                  :isFocus="curStepInput === 3"
                  :isLoadAble="true"
                  @setNextInput="setNextInputNumber"
                />
              </b-col>
              <b-col cols="6" md="6">
                <custom-select
                  label="Colour"
                  v-model="info.color"
                  :options="colors"
                  :error="$v.info.color.$error"
                  helperText="Please select colour"
                  name="color"
                  :isFocus="curStepInput === 4"
                  @setNextInput="setNextInputNumber"
                />
              </b-col>
              <b-col cols="6" md="6">
                <custom-clear-select
                  id="year"
                  label="Year"
                  v-model="info.year"
                  :isClear="info.year !== ''"
                  :options="years"
                  helperText="Please select year"
                  :error="$v.info.year.$error"
                  name="year"
                  :isFocus="curStepInput === 5"
                  :isLoadAble="true"
                  @setNextInput="setNextInputNumber"
                />
              </b-col>
              <b-col cols="12" md="6">
                <custom-clear-select
                  id="variant"
                  label="Variant"
                  v-model="info.badgeEdition"
                  :options="badgeEditions"
                  name="badgeEdition"
                  :isClear="this.isClearOptions.isClearBadgeEdition"
                  :optional="true"
                  :isLoadAble="true"
                  @setNextInput="setNextInputNumber"
                />
              </b-col>
              <b-col cols="12" md="6">
                <custom-clear-select
                  id="body-type"
                  label="Body type"
                  name="bodyType"
                  v-model="info.bodyType"
                  :options="bodyTypes"
                  :optional="true"
                  @setNextInput="setNextInputNumber"
                  :isClear="this.isClearOptions.isClearBody"
                />
              </b-col>
              <b-col cols="12" md="6">
                <custom-clear-select
                  id="drive"
                  label="Drive type"
                  v-model="info.drive"
                  :options="drives"
                  :error="$v.info.drive.$error"
                  :isClear="this.isClearOptions.isClearDrive"
                  helperText="Please select drive type"
                  name="drive"
                  :isFocus="curStepInput === 6"
                  @setNextInput="setNextInputNumber"
                />
              </b-col>
              <b-col cols="12" md="6">
                <custom-clear-select
                  id="transmission"
                  label="Transmission"
                  name="transmission"
                  :options="transmissions"
                  v-model="info.transmission"
                  :error="$v.info.transmission.$error"
                  :isClear="this.isClearOptions.isClearTrans"
                  helperText="Please select transmission"
                  :isFocus="curStepInput === 7"
                  @setNextInput="setNextInputNumber"
                />
              </b-col>
              <b-col cols="12" md="6">
                <custom-select
                  label="Model year"
                  v-model="info.model_year"
                  :options="modelYears"
                  :optional="true"
                  @setNextInput="setNextInputNumber"
                />
              </b-col> 
            </b-row>
          </div>
          <div class="center">
            <SectionTitle sectionTitle="CONDITION"></SectionTitle>
            <b-row>
              <b-col cols="6">
                <float-label label="Odometer">
                  <b-input
                    v-model="info.kms"
                    ref="kmsInput"
                    placeholder="Enter kms"
                    pattern="\d*"
                    :class="[
                      kmsValidate ? 'invalid' : '',
                      curStepInput === 8 ? 'next-input' : '',
                      'shadow-none',
                    ]"
                    @blur="setNextInputNumber"
                    @keypress="isNumber"
                    @keyup="removeFirstZero"
                  />
                </float-label>
                <div class="fs-12 text-right mb-2 mt-n3 pr-2 err-text">
                  {{ kmsValidate }}
                </div>
              </b-col>
                <b-col cols="6">
                  <custom-select
                    label="Owner's Manual"
                    v-model="info.ownerManual"
                    :options="ownerManualOptions"
                    :error="$v.info.ownerManual.$error"
                    helperText="Please select your owner's manual"
                    name="ownerManual"
                    :isFocus="curStepInput === 10"
                  />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <custom-select
                  label="Service history"
                  v-model="info.serviceHistory"
                  :options="serviceHistories"
                  :error="$v.info.serviceHistory.$error"
                  helperText="Please select your service history"
                  name="serviceHistory"
                  :ask="true"
                  :tooltip="serviceHistoryTooltip"
                  :isFocus="curStepInput === 11"
                  @setNextInput="setNextInputNumber"
                />
              </b-col>
              <b-col cols="12" md="6">
                <custom-select
                  label="Service history details"
                  v-model="info.serviceHistoryDetail"
                  :options="serviceHistoryDetails"
                  :error="$v.info.serviceHistoryDetail.$error"
                  helperText="Please select your service history detail"
                  name="serviceHistoryDetail"
                  :selectDisabled="info.serviceHistory === '' || info.serviceHistory === 'None'"
                  :isFocus="curStepInput === 14"
                  @setNextInput="setNextInputNumber"
                />
              </b-col>
              <b-col cols="12" md="6">
                <custom-select
                  label="Number of keys"
                  v-model="info.key"
                  :options="keys"
                  :error="$v.info.key.$error"
                  helperText="Please select how many keys"
                  name="key"
                  :isFocus="curStepInput === 10"
                  @setNextInput="setNextInputNumber"
                />
              </b-col>
              <b-col cols="12" md="6">
                <custom-select
                  label="Tyres needing replacement"
                  v-model="info.tyreReplacement"
                  :options="tyreReplacements"
                  name="tyreReplacement"
                  :optional="true"
                  :isFocus="curStepInput === 13"
                  @setNextInput="setNextInputNumber"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6" class="mt-3">
                <float-label
                  v-b-modal.modal-exterior
                  class="font-weight-bold fw-400 pb-2"
                  label="Exterior condition"
                ></float-label>
                <div style="max-width: 350px">
                  <b-form-rating
                    v-model="info.exterior"
                    :class="[
                      exteriorValidate ? 'bi-star-error' : '',
                      isMobile ? 'ml-1' : 'ml-4',
                      'mb-1 border-0 w-15 px-0 mt-n3 form-stars',
                    ]"
                    size="lg"
                  />
                  <div
                    class="mx-auto font-weight-regular mb-4"
                    v-if="info.exterior"
                  >
                    <p class="text-center fs-14 rate-label mb-1">
                      {{ exteriorRate[info.exterior - 1].text }}
                    </p>
                    <p class="fs-12 text-center mb-1">{{ exteriorRate[info.exterior - 1].desc }}</p>
                  </div>
                </div>
                <div class="fs-12 text-right mb-2 mt-n3 pr-2 err-text">
                  {{ exteriorValidate }}
                </div>
              </b-col>
              <b-col md="6" class="mt-3">
                <float-label
                  v-b-modal.modal-interior
                  class="font-weight-bold fw-400 pb-2"
                  label="Interior condition"
                ></float-label>
                <div style="max-width: 350px">
                  <b-form-rating
                    v-model="info.interior"
                    :class="[
                      interiorValidate ? 'bi-star-error' : '',
                      isMobile ? 'ml-1' : 'ml-4',
                      'mb-2 border-0 w-15 px-0 mt-n3 form-stars',
                    ]"
                    size="lg"
                  />
                  <div
                    class="mx-auto font-weight-regular mb-4"
                    v-if="info.interior"
                  >
                    <p class="text-center fs-14 rate-label mb-1">
                      {{ interiorRate[info.interior - 1].text }}
                    </p>
                    <p class="fs-12 text-center mb-1">{{ interiorRate[info.interior - 1].desc }}</p>
                  </div>
                </div>
                <div class="fs-12 text-right mb-2 mt-n3 pr-2 err-text">
                  {{ interiorValidate }}
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-4">
              <b-col class="px-2">
                <custom-text-area
                  label="Car description"
                  v-model="$v.info.description.$model"
                  :rows="isMobile ? '8' : '5'"
                  :placeholder="descriptionPlaceholder"
                  :error="$v.info.description.$error"
                  helperText="Please enter less than 2,000 characters"
                  name="carDescription"
                  optional
                />
              </b-col>
            </b-row>
          </div>
          <div :style="isMobile ? 'height: 20px;' : 'height: 40px'"></div>
          <btn-group
            @onOk="next"
            @onCancel="() => {}"
            back="top-page"
            :okBtnDisable="isValid"
            :customClasses="`${!isMobile ? 'custom-padding' : ''}`"
            :isCarInfoPage="true"
          />
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { 
  FETCH_DETAIL, 
  SET_INFO, 
  FETCH_INIT,
  FETCH_MODELS,
  FETCH_YEARS,
  FETCH_BADGES ,
  CLEAR_INFO_EXTEND,
  SET_PREV_LENGTH,
  CLEAR_SERVICE_HISTORY_DETAIL,
  SET_OTHER_OPTIONS,
  SET_RB_LOOKUP_FINISH,
  CLEAR_RELEVANT_RBDESC,
} from "@/store/info/actions";
import regex, { formatNumberWithComma } from "../../helper/utils";
import mixin from "@/mixins/common";
import { required, maxLength } from "vuelidate/lib/validators";
import { EXTERIOR_RATE, INTERIOR_RATE } from "@/config/constants";
import { eraseCookie, getCookie } from "../../helper/cookie";
import { blankOptionList } from "@/helper/constant";

export default {
  name: "Index",
  mixins: [mixin],
  components: {
    CustomSelect: () => import("../../components/common/CustomSelect"),
    CustomAutocompleteInput: () => import("../../components/common/CustomAutocompleteInput"),
    CustomClearSelect: () => import("../../components/common/CustomClearSelect"),
    EditForm: () => import("../../components/vehicle/Form"),
    BtnGroup: () => import("../../components/common/BtnGroup.vue"),
    FloatLabel: () => import("../../components/common/FloatLabel.vue"),
    SectionTitle: () => import("../../components/vehicle/SectionTitle.vue"),
    CustomTextArea: () => import("../../components/common/CustomTextArea.vue"),
  },
  data() {
    return {
      onEdit: false,
      submitted: false,
      exteriorRate: [...EXTERIOR_RATE],
      interiorRate: [...INTERIOR_RATE],
      descriptionPlaceholder: "Provide any relevant details about your car that will be useful to potential buyers e.g. car accessories, add-ons, custom fittings, damage, scratches, dents, rust, peeling paint, tyre tread, etc.",
      curStepInput: 1,
      inputStep: [
        {
          step: 1,
          key: "location",
        },
        {
          step: 2,
          key: "make",
        },
        {
          step: 3,
          key: "model",
        },
        {
          step: 4,
          key: "color",
        },
        {
          step: 5,
          key: "year",
        },
        {
          step: 6,
          key: "drive",
        },
        {
          step: 7,
          key: "transmission",
        },
        {
          step: 8,
          key: "kms",
        },
        {
          step: 9,
          key: "key",
        },
        {
          step: 10,
          key: "ownerManual",
        },
        {
          step: 11,
          key: "serviceHistory",
        },
        {
          step: 12,
          key: "exterior",
        },
        {
          step: 13,
          key: "interior",
        },
        {
          step: 14,
          key: "",
        },
      ],
      beforeUpdateBadges: this.$store.getters.badges,
      isMobile: true,
      tyreReplacements: [
        "No replacement",
        "One tyre",
        "Two tyres",
        "Three tyres",
        "Four tyres",
      ],
      serviceHistoryDetails: [
        {
          key: "Genuine - with a franchise dealer",
          value: "Genuine",
        },
        {
          key: "Non-genuine - with an independent authorised service agent",
          value: "Non-genuine",
        },
        {
          key: "Mixed",
          value: "Mixed",
        },
      ],
      serviceHistoryTooltip: {
        Full:
          " - You must have evidence of complete and accurate records of service history from purchase until date. It must be stamped or invoiced by a franchise dealer or independent service agent.",
        Partial:
          " - Some records of service history are missing. You must have evidence of the records you have.",
      },
      rbLookupFinish: this.$store.getters.rbLookupFinish,
      regoSearch: this.$store.getters.regoSearch,
    };
  },
  computed: {
    ...mapState({
      info: (state) => state.info.curVehicleInfo,
      makes: (state) => state.info.makes,
      models: (state) => state.info.models,
      years: (state) => state.info.years,
      colors: (state) => state.info.colors,
      badges: (state) => state.info.badges,
      editions: (state) => state.info.editions,
      drives: (state) => state.info.drives,
      transmissions: (state) => state.info.transmissions,
      shifts: (state) => state.info.shifts,
      steps: (state) => state.common.steps,
      bodyTypes: (state) => state.info.bodyTypes,
      badgeEditions: (state) => state.info.badgeEditions,
      isClearOptions: (state) => state.info.isClearOptions,
      modelYears: (state) => state.info.modelYears,
    }),
    // modelYears: function () {
    //   const temp = modelYearRange();
    //   return ['Not sure', ...temp];
    // },
    states: function () {
      return ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"];
    },
    isValid: function () {
      return !this.$v.info.$invalid;
    },
    kmsValidate: function () {
      if (this.submitted && !this.info.kms) return "Please enter kms";
      //eslint-disable-next-line
      if (this.submitted && regex("^-?[\d\,]+$", this.info.kms))
        return "Please enter only numerics";
      return "";
    },
    exteriorValidate: function () {
      if(this.submitted && this.info.exterior === null) return "Please select exterior condition";
      return "";
    },
    interiorValidate: function () {
      if(this.submitted && this.info.interior === null) return "Please select interior condition";
      return "";
    },
    badgeRequestValue: function() {
      return !blankOptionList.includes(this.info.badgeEdition) ? this.info.badgeEdition : ''
    },
    keys: function() {
      return ["1", "2", "3 or more"];
    },
    serviceHistories: function() {
      return ["Full", "Partial", "None"];
    },
    ownerManualOptions: function() {
      return ["Yes", "No"];
    },
    mobileMediaQuery: function() {
      return window.matchMedia("(min-width: 768px)");
    },
  },
  methods: {
    next: function () {
      this.submitted = true;
      this.$v.info.$touch();
      if (this.isValid) {
        this.$store.dispatch(SET_INFO, {
          ...this.info,
        });
        this.$store.dispatch("setProgress", 48);
        this.$store.dispatch(SET_RB_LOOKUP_FINISH, true);
        this.$router.push({ name: "photo-upload", query: this.$route.query });
      }
    },
    getData: function () {
      if (this.rbLookupFinish === false) {
        this.$store.dispatch(FETCH_INIT, {
          model: this.info.model,
          make: this.info.make,
          year: this.info.year,
          badge: this.info.badge,
        });
      }
    },
    changeData: function () {
      this.info.badge = "";
    },
    setNextInputNumber() {
      for (const item of this.inputStep) {
        if (!this.info[item.key] || item.key === "") {
          this.curStepInput = item.step;
          break;
        }
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode >= 48 &&
        charCode <= 57 &&
        !(charCode === 48 && this.info.kms === "")
      ) {
        return true;
      } else {
        evt.preventDefault();
        return false;
      }
    },
    removeFirstZero() {
      if (/^0/.test(this.info.kms)) {
        this.info.kms = this.info.kms.replace(/^0+/, "");
      }
    },
    fetchDetailRequestValues(name, val) {
      const allRequestValues = {
        ...(this.info.make ? { make: this.info.make } : {}),
        ...(this.info.model && this.info.model !== "Other" ? { model: this.info.model } : {}),
        ...(this.info.year ? { year: this.info.year } : {}),
        ...(this.info.badge && !blankOptionList.includes(this.info.badge) ? { badge: this.info.badge } : {}),
        ...(this.info.edition && !blankOptionList.includes(this.info.edition) ? { edition: this.info.edition } : {}),
        ...(this.info.bodyType ? { bodyType: this.info.bodyType } : {}),
        ...(this.info.transmission ? { transmission: this.info.transmission } : {}),
        ...(this.info.drive ? { drive: this.info.drive } : {}),
      }
      return {
        ...allRequestValues,
        [name]: val && val !== 'Not sure' ? val : ''
      }
    },
  },
  validations: {
    info: {
      make: {
        required,
      },
      model: {
        required,
      },
      year: {
        required,
      },
      color: {
        required,
      },
      location: {
        required,
      },
      kms: {
        required,
      },
      ownerManual: {
        required,
      },
      serviceHistory: {
        required,
      },
      serviceHistoryDetail: {
        required: function() {
          return this.info.serviceHistory === 'None' || this.info.serviceHistoryDetail !== ''
        },
      },
      drive: {
        required: function() {
          return this.drives.length <= 1 || this.info.drive !== '';
        },
      },
      transmission: {
        required: function() {
          return this.transmissions.length <= 1 || this.info.transmission !== '';
        },
      },
      exterior: {
        required,
      },
      interior: {
        required,
      },
      key: {
        required,
      },
      description: {
        maxLength: maxLength(2000)
      },
    },
  },
  watch: {
    "info.location": function () {
      this.curStepInput = 2;
    },
    "info.make": function (val) {
      if (val) {
        this.$store.dispatch(FETCH_MODELS, {
          make: val,
        });
        this.curStepInput = 3;
        this.onChange = true;
      }
      if ((Object.keys(this.regoSearch.rego).length > 0) && (this.regoSearch.rego.make !== val)) {
        this.$store.dispatch(CLEAR_RELEVANT_RBDESC);
      }
    },
    "info.model": function (val) {
      if (val) {
        if (val !== 'Other') {
          this.$store.dispatch(CLEAR_INFO_EXTEND);
          this.$store.dispatch(FETCH_YEARS, {
            make: this.info.make,
            model: val,
          });
          this.curStepInput = 4;
          this.onChange = true;
        } else {
          this.$store.dispatch(CLEAR_INFO_EXTEND);
          this.$store.dispatch(SET_OTHER_OPTIONS, {
            make: this.info.make,
            model: '',
          });
        }
      }
      if ((Object.keys(this.regoSearch.rego).length > 0) && (this.regoSearch.rego.model !== val)) {
        this.$store.dispatch(CLEAR_RELEVANT_RBDESC);
      }
    },
    "info.color": function(val) {
      if (val) {
        this.curStepInput = 5;
      }
    },
    "info.year": function (val) {
      if (val && this.info.model !== 'Other') {
        this.$store.dispatch(CLEAR_INFO_EXTEND);
        this.$store.dispatch(FETCH_BADGES, {
          make: this.info.make,
          model: this.info.model,
          year: val,
        });
        this.curStepInput = 6;
      }
      if ((Object.keys(this.regoSearch.rego).length > 0) && (this.regoSearch.rego.year !== val)) {
        this.$store.dispatch(CLEAR_RELEVANT_RBDESC);
      }
    },
    "info.badgeEdition": function (val) {
      const splittedVal = val.split("|");
      this.info.badge = splittedVal[0];
      this.info.edition = splittedVal[1] === "" ? 'N/A' : splittedVal[1];
      if (
        val &&
        val.split('|').some(value => !blankOptionList.includes(value)) &&
        this.info.model !== "Other"
      ) {
        this.$store.dispatch(SET_PREV_LENGTH, {prevLengthName: 'prevLengthBadgeEdition', stateName: 'badgeEditions' });
        this.$store.dispatch(SET_PREV_LENGTH, {prevLengthName: 'prevLengthBody', stateName: 'bodyTypes' }); 
        this.$store.dispatch(SET_PREV_LENGTH, {prevLengthName: 'prevLengthDrive', stateName: 'drives' });
        this.$store.dispatch(SET_PREV_LENGTH, {prevLengthName: 'prevLengthTrans', stateName: 'transmissions' });
        this.$store.dispatch(FETCH_DETAIL, this.fetchDetailRequestValues('badge', splittedVal[0]));
      }
    },
    "info.bodyType": function (val) {
      if (val && this.info.model !== 'Other') {
        this.$store.dispatch(SET_PREV_LENGTH, {prevLengthName: 'prevLengthBody', stateName: 'bodyTypes' });
        this.$store.dispatch(FETCH_DETAIL, this.fetchDetailRequestValues('bodyType', val));
      }
    },
    "info.drive": function (val) {
      if (val && this.info.model !== 'Other') {
        this.$store.dispatch(SET_PREV_LENGTH, {prevLengthName: 'prevLengthDrive', stateName: 'drives' });
        this.$store.dispatch(FETCH_DETAIL, this.fetchDetailRequestValues('drive', val));
        this.curStepInput = 7;
      }
    },
    "info.transmission": function (val) {
      if (val && this.info.model !== 'Other') {
        this.$store.dispatch(SET_PREV_LENGTH, {prevLengthName: 'prevLengthTrans', stateName: 'transmissions' });
        this.$store.dispatch(FETCH_DETAIL, this.fetchDetailRequestValues('transmission', val));
        this.curStepInput = 8;
      }
    },
    "info.kms"() {
      this.$nextTick(() => this.info.kms = formatNumberWithComma(this.info.kms));
      this.curStepInput = 9;
    },
    "info.key": function() {
      this.curStepInput = 10;
    },
    "info.ownerManual": function() {
      this.curStepInput = 11;
    },
    "info.serviceHistory": function(val) {
      if (val === 'None') {
        this.$store.dispatch(CLEAR_SERVICE_HISTORY_DETAIL);
      }
    },
    "info.exterior": function() {
      this.curStepInput = 13;
    },
    "info.interior": function() {
      this.curStepInput = 14;
    },
    curStepInput: function() {
      let progress = 0;
      for (const item of this.inputStep) {
        if (this.info[item.key] && item.key !== "") {
          progress = progress + (10 / (this.inputStep.length - 1));
        }
      }
      // this.$store.dispatch("setProgress", Math.floor(progress) * 10);
    },
  },
  created: function () {
    this.getData();
  },
  mounted() {
    this.mobileMediaQuery.addListener((e) => {
      if (e.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });

    this.isMobile = !this.mobileMediaQuery.matches;
    this.$nextTick(
      () => (this.info.kms = formatNumberWithComma(this.info.kms))
    );

    window.onpopstate = function () {
      if (getCookie("qs").includes("enc")) {
        window.history.back();
        eraseCookie("qs");
      }
    };
  },
};
</script>

<style lang="scss">
.form-stars {
  .b-rating-star {
    padding: 0 10px !important;
    z-index: 9;
  }
  .b-rating-icon {
    font-size: 40px;
  }
}

.content {
  margin: 0 auto;
  text-align: left;
  min-height: calc(100vh - 179px);
  overflow: auto;
  .card {
    border: none;
  }
}

.auto-info {
  background-color: #f2f2f2;
  border-color: #b3b3b3;
  margin-left: 10px;
  margin-right: 0;
}
.next-input {
  color: #383C3F;
  background-color: #fff;
  border-color: #00bb65;
  outline: 0;
}
.rate-label {
  color: #2BB673;
  text-align: center;
  text-transform: uppercase;
}

.bi-star-error{
  svg{
    path {
      fill: red;
    }
  }
}
.vehicle-condition-item {
  // padding-top: 25px;

  .star-rating {
    height: fit-content;
    padding: 0;
    margin-top: 6px;
    width: 70%;

    &:focus {
      box-shadow: none;
    }

    span.b-rating-star {
      padding: 0 4px;

      &:first-child {
        padding-left: 0;
      }

      svg {
        path {
          fill: #2BB673 ;
          }
        width: 35px;
        height: 35px;
      }
    }

    .b-rating-star-full {
      color: #00C44B;
    }
  }
}
#modal-exterior,
#modal-interior {
  .modal {
    padding-left: unset !important;
  }
  .modal-header {
    border-bottom: 0;
    padding: 1.6rem 1rem 0;
    .close {
      color: #2bb673;
      opacity: 1;
      outline: none !important;
      &:hover {
        opacity: 0.5;
      }
    }
  }
  .modal-title {
    font-weight: bold;
  }
  .modal-body {
    padding-top: 0 !important;
  }
  .modal-content {
    border-radius: 1rem;
    border: 4px solid #2bb673;
  }
  hr {
    background-color: #c4c4c4 !important;
  }
}

@media screen and(min-width: 768px) {
  // .form-stars {
  //   margin-left: 116px !important;
  //   position: relative;
  // }
  // .rate-label-wrapper {
  //   position: absolute;
  //   margin-left: 1px;
  // }
  .card {
    padding: 0 48px !important;
    overflow-x: hidden;
  }

  .top-description {
    margin-left: 70px !important;
  }

  .condition-title {
    font-size: 0.9rem !important;
  }

  .vehicle-info-content {
    .btn-group-wrapper.custom-padding {
      // 100vh - header - stepper - content - button group height
      padding-bottom: max(calc(100vh - 75px - 102px - 640px - 64px), 40px);
    }
  }

  .content {
    overflow-x: hidden;
  }

  .form-stars,
  .rate-label-wrapper {
    justify-content: unset !important;
    margin-left: 1.5rem !important;
  }

  .modal-interior {
    max-width: 1080px;
  }

  .star-rating {
    width: 100%;
  }

  #modal-exterior,
  #modal-interior {
    .modal-title {
      width: 100% !important;
      text-align: center !important;
      padding-left: 56px !important;
      font-size: 24px;
    }
  }
}

// Tablet
@media screen and (min-width: 768px ) and (orientation: portrait) {
  .form-stars {
    .b-rating-icon {
      font-size: 37px;
    }
  }
  #modal-exterior,
  #modal-interior {
    .modal-dialog {
      top: -10% !important;
    }
    .modal-dialog {
      max-width: 777px;
    }
  }
  .form-stars,
  .rate-label-wrapper {
    margin-left: 0.5rem !important;
  }
}

@media screen and(min-width: 356px) {
  .top-description {
    margin-left: 22px;
  }
}
</style>

